exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-contact-content-sidebar-cta-slider-js": () => import("./../../../src/templates/contact-content-sidebar-cta-slider.js" /* webpackChunkName: "component---src-templates-contact-content-sidebar-cta-slider-js" */),
  "component---src-templates-content-cards-cta-slider-js": () => import("./../../../src/templates/content-cards-cta-slider.js" /* webpackChunkName: "component---src-templates-content-cards-cta-slider-js" */),
  "component---src-templates-content-halfhalf-dots-content-slider-js": () => import("./../../../src/templates/content-halfhalf-dots-content-slider.js" /* webpackChunkName: "component---src-templates-content-halfhalf-dots-content-slider-js" */),
  "component---src-templates-content-sidebar-cta-slider-js": () => import("./../../../src/templates/content-sidebar-cta-slider.js" /* webpackChunkName: "component---src-templates-content-sidebar-cta-slider-js" */),
  "component---src-templates-content-sidebar-slider-cta-js": () => import("./../../../src/templates/content-sidebar-slider-cta.js" /* webpackChunkName: "component---src-templates-content-sidebar-slider-cta-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/newsItem.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-nieuws-js": () => import("./../../../src/templates/nieuws.js" /* webpackChunkName: "component---src-templates-nieuws-js" */),
  "component---src-templates-speltak-item-js": () => import("./../../../src/templates/speltakItem.js" /* webpackChunkName: "component---src-templates-speltak-item-js" */)
}

